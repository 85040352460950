<template>
  <base-dialog
    type="icon"
    :width="1200"
    @click_open="load"
    title="Añadir horario"
    ref="dialog"
  >
    <form
      action
      data-vv-scope="timetable-form"
      @submit.prevent="validateForm('timetable-form')"
    >
      <v-row v-if="form" justify="center">
        <v-col class="py-0" cols="12" md="3">
          <label>{{ $tc("expenses.concepto", 1) }}</label>
          <v-text-field
            outlined
            hide-details
            v-model="form.concepto"
            v-validate="'required'"
            :error-messages="errors.collect('timetable-form.concepto')"
            dense
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="6" md="2">
          <label>Fecha inicio</label>
          <v-menu
            ref="menu"
            v-model="menu"
            transition="scale-transition"
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                hide-details
                dense
                class="filters"
                readonly
                :disabled="indef"
                v-bind="attrs"
                v-validate="'required'"
                :error-messages="errors.collect('timetable-form.d_start')"
                v-on="on"
                :value="
                  form.d_start ? $d(new Date(form.d_start), 'date2digits') : ''
                "
              ></v-text-field>
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="form.d_start"
              no-title
              scrollable
              color="primary"
              @input="menu2 = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col class="py-0" cols="6" md="2">
          <label>Fecha Fin</label>
          <v-menu
            ref="menu"
            v-model="menu2"
            transition="scale-transition"
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                hide-details
                dense
                class="filters"
                readonly
                v-bind="attrs"
                :disabled="indef"
                v-on="on"
                :value="
                  form.d_end ? $d(new Date(form.d_end), 'date2digits') : ''
                "
              ></v-text-field>
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="form.d_end"
              no-title
              scrollable
              color="primary"
              @input="menu2 = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col class="py-0 sm-ml-2 mt-8" cols="12" md="2">
          <v-checkbox v-model="indef" @input="form.d_end = null">
            <template v-slot:label>
              <span class="primary--text">{{
                $t("tattooers.invites.indef")
              }}</span>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12">
          <timetable
            :timetable="form.timetable"
            v-if="form"
            :from="form.d_start"
            :to="form.d_end"
          />
        </v-col>
      </v-row>
    </form>

    <template slot="actions">
      <v-spacer />
      <v-btn outlined height="30" @click="$refs.dialog.close()">
        {{ $t("cancel", { name: "" }) }}
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        elevation="0"
        style="
          width: 100px !important;
          color: #363533;
          height: 25px;
          padding-top: 10px !important;
        "
      >
        <v-icon small style="margin-right: 5px" size="14px">$save</v-icon>
        {{ $t("save", { name: $t("") }) }}
      </v-btn>
    </template>
  </base-dialog>
</template>
<script>
import * as moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "AddTimeTable",
  components: {
    timetable: () => import("@/components/ui/Timetable"),
  },

  props: {
    tattooer_id: {
      type: Number,
    },
    box_id: {
      type: Number,
    },
    studio_id: {
      type: Number,
    },
  },
  data() {
    return {
      form: null,
      concepto: "",
      menu: false,
      menu2: false,
      indef: false,
    };
  },
  methods: {
    load() {
      this.form = {
        timetable: this.$store.getters["timetables/basic"],
        form: moment().format("Y-m-d"),
        to: null,
      };
      this.indef = false;
    },
    ...mapActions("timetables", ["addTimetable"]),
    async validateForm(scope) {
      console.log("SAVE");
      this.errors.clear();
      let result = await this.$validator.validateAll(scope);
      if (result) {
        let form = {
          ...this.form,
          tattooer_id: this.tattooer_id,
          studio_id: this.studio_id,
          box_id: this.box_id,
        };
        this.addTimetable(form).then(() => {
          this.$refs.dialog.close();
          this.$emit("update");
        });
      }
    },
  },
};
</script>

<style lang="sass">
label
  height: 25px
  display: block
</style>
